import api from '@/apis/config'
const { post } = api

//통합 배정판 배정 현황
export const bookingStatus = async params => {
	const response = await post(
		`${process.env.VUE_APP_API_SCHEDULE_URL}/booking/schedule-board/status`,
		{
			...params
		}
	)

	return response
}

//제품별 작업 담당 조직 구분 조회
export const workerType = async params => {
	const response = await post(
		`${process.env.VUE_APP_API_SCHEDULE_URL}/booking/worker/type`,
		{
			...params
		}
	)
	return response
}
