<template>
	<div
		class="cw-product-cart"
		:class="{ active: bottomPannel }"
		v-if="hasPrdsAndInit()"
	>
		<div class="product-handle">
			<v-btn icon @click="clickOpen()">
				<v-icon>mdi-chevron-up</v-icon>
			</v-btn>
		</div>

		<!-- 선택상품 썸네일 -->
		<div class="product-thumb">
			<div class="inner">
				<v-btn
					class="btn-img"
					@click="clickOpen()"
					v-for="(prd, i) in prevList"
					:key="i"
				>
					<img
						:src="`${prd.prd.color_file_path}`"
						:alt="prd.prd.leader_model_nm"
					/>
				</v-btn>
			</div>
		</div>
		<!--// 선택상품 썸네일 -->

		<!-- 선택상품 리스트 -->
		<div class="product-list">
			<div class="itembox" v-for="(prd, i) in prevList" :key="i">
				<dl>
					<dt>
						<img
							:src="`${prd.prd.color_file_path}`"
							:alt="prd.prd.leader_model_nm"
						/>
					</dt>
					<dd>
						<h3 class="tit">{{ prd.prd.leader_model_nm }}</h3>
						<h4 class="type">
							<p>
								<span>{{ getSellType(prd.options.sellType) }}</span>
								{{ prd.prd.prod_nm }}, {{ prd.prd.erp_cd }}
								<template v-if="prd.prd.kind_val_txt"
									>, {{ prd.prd.kind_val_txt }}</template
								>
							</p>
						</h4>
						<h5 class="desc">
							<template v-if="prd.options.prdOption1">
								<p>{{ prd.options.prdOption1.text }}</p>
							</template>
							<template v-if="prd.options.freeStand">
								<p>{{ prd.options.freeStand.text }}</p>
							</template>
							<template v-if="prd.options.hangerType">
								<p>{{ prd.options.hangerType.text }}</p>
							</template>
							<template v-if="prd.options.hangerType2">
								<p>{{ prd.options.hangerType2.text }}</p>
							</template>
							<template v-if="prd.options.deliveryType">
								<p>{{ prd.options.deliveryType.text }}</p>
							</template>
							<template v-if="prd.options.filterType">
								<p>{{ prd.options.filterType.text }}</p>
							</template>
							<template v-if="prd.options.filterType2">
								<p>{{ prd.options.filterType2.text }}</p>
							</template>
							<template v-if="prd.options.filterType3">
								<p>{{ prd.options.filterType3.text }}</p>
							</template>
							<template v-if="prd.options.filterType4">
								<p>{{ prd.options.filterType4.text }}</p>
							</template>
						</h5>
						<v-btn class="btn-del" icon @click="delPrd(prd, i)"
							><v-icon>mdi-close</v-icon>삭제</v-btn
						>
					</dd>
				</dl>
				<div class="info">
					<div class="price">
						<!-- 등록비 -->
						<p
							v-if="
								prd.options.sellType.code === 'Z001' ||
									prd.options.sellType.code === 'Z002'
							"
						>
							<span>등록비</span>
							<em>
								{{ (prd.esProd.REG_PRICE * prd.options.quantity) | comma }}
							</em>
							원
						</p>
						<!--// 등록비 -->
						<!-- 렌타료 -->
						<p
							v-if="
								prd.options.sellType.code === 'Z001' ||
									prd.options.sellType.code === 'Z002'
							"
						>
							<span>렌탈료</span>
							<em>
								{{ (prd.esProd.MONTH_AMT * prd.options.quantity) | comma }}
							</em>
							원
						</p>
						<!--// 렌타료 -->
						<!-- 일시불 -->
						<p
							v-if="
								prd.options.sellType.code === 'Z003' ||
									prd.options.sellType.code === 'Z004' ||
									prd.options.sellType.code === 'Z005'
							"
						>
							<span>일시불</span>
							<em>
								{{ (prd.esProd.PRICE * prd.options.quantity) | comma }}
							</em>
							원
						</p>
						<!-- 일시불 -->
					</div>

					<div class="counter">
						<MNumericUpDown
							v-model="prd.options.quantity"
							:sellType="prd.options.sellType.code"
						/>
					</div>
				</div>
			</div>
		</div>
		<!--// 선택상품 리스트 -->
	</div>
</template>
<script>
/**
 * 상품 장바구니
 * @description
 */
import filters from '@/mixins/filters'
import { isProduction } from '@/utils'
import computeds from '@/mixins/computeds'
import { getSellType, hasPrds } from '@/utils/ec'

export default {
	mixins: [filters, computeds],
	props: {
		bottomPannel: {
			type: Boolean
		}
	},
	data() {
		return {
			prevList: [], // 상품 리스트
			isProduction: isProduction()
		}
	},
	created() {},
	methods: {
		getSellType,
		hasPrds,

		// 장바구니 펼침, 접힘
		clickOpen() {
			this.$emit('bottomPannel', !this.bottomPannel)
		},

		// 상품 추가
		hasPrdsAndInit() {
			this.prevList = this.$store.getters['prd/prdList']
			return this.hasPrds()
		},

		// 상품 삭제
		delPrd(prd, i) {
			this.$store.dispatch('prd/delPrd', { prd, i })
		}
	}
}
</script>
