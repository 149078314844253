<template>
	<div class="cw-app-wrapper promotion">
		<!--components-->
		<div class="cw-app-module" :class="{ bt_100: !hasPrds() }">
			<MStepper />
		</div>
		<!--//components-->

		<!-- 상품 담기 완료 안내 -->
		<div v-if="this.$store.getters['prd/prdChk']" class="impact_area">
			<span class="add_txt">
				Tip
			</span>
			주문을 위해 담겨진 상품은 '상품검색' 화면 하단에서 확인할 수 있어요!
		</div>
		<v-dialog
			v-if="this.$store.getters['prd/prdChk']"
			v-model="this.$store.getters['prd/prdChk']"
			persistent
			content-class="position_top"
			@keydown.esc="cancel"
			scrollable
		>
			<v-card class="aalert">
				<v-card-title>
					상품 담기 완료!
				</v-card-title>
				<v-card-text>
					선택한 상품을 담았습니다. <br />
					주문할 상품을 모두 담으셨다면<br />
					할인정보를 입력해 주세요. <br />
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-spacer></v-spacer>
					<v-btn class="btn_sub " text @click="prdChkReset">
						상품 추가하기
					</v-btn>
					<v-btn class="btn_confirm " text @click="next()">
						할인정보 입력
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!--// 상품 담기 완료 안내 -->

		<!-- 상품검색 -->
		<div class="cw-app-section">
			<div class="u-app-title">상품검색</div>
			<v-form
				class="u-app-search"
				ref="prdSearchForm"
				@submit.prevent="clickSearch"
			>
				<div class="flex-input">
					<v-text-field
						dense
						outlined
						class="search"
						inputmode="text"
						prepend-inner-icon="mdi-magnify"
						placeholder="제품명/종류/모델명/상품코드"
						@click:prepend-inner="clickSearch"
						v-model.trim="searchStr"
						:rules="rules.searchStr"
						@click="bottomPannelClick(false)"
					>
					</v-text-field>
				</div>

				<div class="flex-button">
					<v-btn large @click="clickSearch">검색</v-btn>
				</div>
			</v-form>

			<!-- 상품 : 카운트 -->
			<div class="wrap_search_infor" v-if="prdSearchshow">
				<p class="count">
					<span>총</span>
					<strong>{{ searchPrdCount | comma }}</strong>
					<span>건</span>
				</p>
			</div>
			<!--// 상품 : 카운트 -->

			<!-- 상품 : 검색 리스트 -->
			<div class="warp_item_box" v-if="prdSearchshow">
				<ul
					class="wrap_item_list"
					v-if="prdList && prdList.length > 0 && !loading"
				>
					<li class="" v-for="(prd, i) in prdList" :key="i">
						<div class="item_list">
							<div class="pic_area">
								<img
									:src="`${prd.color_file_path}`"
									:alt="prd.leader_model_nm"
									@click="openPrdPicture(prd)"
								/>
							</div>
							<div class="txt_area" @click="goPrdDetail(prd)">
								<h3 class="tit">{{ prd.leader_model_nm }}</h3>
								<p class="sub_tit">
									{{ prd.prod_nm }}, {{ prd.erp_cd }}
									<template v-if="prd.kind_val_txt"
										>, {{ prd.kind_val_txt }}
									</template>
								</p>
								<dl class="price_area">
									<dt>등록비/렌탈료</dt>
									<dd>
										{{ prd.reg_price | comma }}/{{ prd.month_amt | comma }}
									</dd>
								</dl>
								<dl class="price_area">
									<dt>일시불</dt>
									<dd>{{ prd.price | comma }}</dd>
								</dl>
							</div>
						</div>
					</li>
				</ul>
				<div
					class="pre-no-data"
					v-else-if="prdList && prdList.length === 0 && loading"
				>
					<img
						src="/img/img_no_data.png"
						alt="조회중입니다. 잠시만 기다려주세요."
					/>
					<p>조회중입니다.<br />잠시만 기다려주세요.</p>
				</div>

				<div class="pre-no-data" v-else>
					<img src="/img/img_no_data.png" alt="검색결과가 없습니다." />
					<p>검색결과가 없습니다.</p>
				</div>
			</div>
			<!--// 상품 : 검색 리스트 -->
		</div>
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn
						large
						class="u-btn-active"
						@click="next()"
						:disabled="!hasPrds()"
					>
						할인정보 입력하기
					</v-btn>
				</div>
			</div>
		</div>

		<!--// 상품검색 -->

		<!-- 팝업 컴포넌트 -->
		<!-- 장바구니, 미리보기 -->
		<PrdPreview
			ref="prdPreview"
			:bottomPannel="bottomPannel"
			@bottomPannel="bottomPannelClick"
		/>
		<!-- 침대 패키지 설정 -->
		<!--// 팝업 컴포넌트 -->
		<!--상품이미지 컴포넌트 -->
		<PrdPictureDialog ref="prdPicture" />
	</div>
</template>

<script>
import {
	mOrderInfoByEtc,
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import { workerType } from '@/apis/order.schedule.api'
import { fetchPrds, fetchPrdOpts01 } from '@/apis/order.prd.api'
import filters from '@/mixins/filters'
import computeds from '@/mixins/computeds'
import { regex } from '@/utils/rules'
import { isProduction } from '@/utils'
import { generateRandomHash } from '@/utils/hash'
import { registerCallbackToApp, closePopView } from '@/utils/navigation'
import { hasPrds, getMessageByStatusCode } from '@/utils/ec'
import PrdPreview from '@/views/prd/PrdPreview'
import { getPreContractSalesChk } from '@/apis/pre.contract.api'
import { isParameterEmpty } from '@/utils/common'

const SALE_INFO = {
	prdAmt: [],
	saleAmt: []
}

export default {
	mixins: [filters, computeds],
	components: {
		PrdPreview
	},
	created() {
		this.initAppPage()

		// 일반주문, 청약 주문 구분값 저장
		if (this.$store.state['verify'].customer.orderType !== '') {
			this.orderType = this.$store.state['verify'].customer.orderType
		} else {
			this.orderType = 'N'
		}
	},
	data() {
		return {
			dialog: [],
			dimDialog: true,
			bottomPannel: false, // 하단 장바구니 펼침, 접힘
			prdSearchshow: false, // 상품 검색결과 노출 여부
			searchStr: '', // 검색 내용
			prdList: [], // 상품 리스트 (상품검색용)
			products: [], // 상품 리스트 (침대패키지설정, 할인정보)
			searchPrdCount: 0,
			rules: {
				searchStr: [v => this.rulesSearchStr(v)]
			},
			filterList: [],
			workerTypeList: [],
			previewSize: 0,
			isProduction: isProduction(),
			orderType: '', // 주문구분값 (사전청약(S), 일반주문(N)
			loading: false
		}
	},
	methods: {
		hasPrds,
		getMessageByStatusCode,
		isParameterEmpty,
		// 상품 장바구니 패널
		bottomPannelClick(param) {
			this.bottomPannel = param
		},

		// 상품 이미지 상세보기
		openPrdPicture(prd) {
			this.$refs.prdPicture.open(prd)
		},

		// 정렬
		changeSort(code) {
			switch (code) {
				case 'NEW':
					this.prdList.sort((a, b) => {
						if (a.come_out_dt < b.come_out_dt) return 1
						if (a.come_out_dt > b.come_out_dt) return -1
						return 0
					})
					break
				case 'LOWRENT':
					this.prdList.sort((a, b) => {
						if (a.month_amt > b.month_amt) return 1
						if (a.month_amt < b.month_amt) return -1
						return 0
					})
					break
				case 'HIGHRENT':
					this.prdList.sort((a, b) => {
						if (a.month_amt < b.month_amt) return 1
						if (a.month_amt > b.month_amt) return -1
						return 0
					})
					break
				case 'LOWDEBIT':
					this.prdList.sort((a, b) => {
						if (a.price > b.price) return 1
						if (a.price < b.price) return -1
						return 0
					})
					break
				case 'HIGHDEBIT':
					this.prdList.sort((a, b) => {
						if (a.price < b.price) return 1
						if (a.price > b.price) return -1
						return 0
					})
					break
			}
		},

		// 상품 검색
		clickSearch() {
			this.loading = true
			this.$refs.prdPreview.bottomPannel = false
			if (!this.$refs.prdSearchForm.validate()) {
				return
			}
			if (!this.prdSearchshow) {
				this.prdSearchshow = !this.prdSearchshow
			}

			fetchPrds({
				search_string: this.searchStr,
				codyNo: this.$store.getters['common/codyNo'],
				customerNo: this.$store.getters['verify/custNo']
			})
				.then(res => {
					if (res.resultObject.result) {
						this.prdList = res.resultObject.result
						this.searchPrdCount = this.prdList.length
					} else {
						this.prdList = []
						this.searchPrdCount = 0
					}

					if (this.prdList.length > 0) this.changeSort('NEW')
					this.prdList.forEach(() => {
						this.dialog.push(false)
					})

					this.loading = false
				})
				.finally({})
		},

		// 검색 입력값 검증 및 안내 메세지
		rulesSearchStr(v) {
			const re = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\\*|\\-]+$/
			return regex(v, re, '검색어를 한글, 영문, 숫자, -만 입력해 주세요.')
		},

		// 상품선택 상세
		async goPrdDetail(prd) {
			let params = {
				prd: prd
			}

			// 사전예약으로 담은 상품의 갯수, 가능 갯수 체크
			if (this.orderType === 'S') {
				const prdList = this.$store.getters['prd/prdList']
				if (prdList.length > 1) {
					const preOrderItems =
						prdList[prdList.length - 1].options.preOrderItems
					let preOrderSubCount = 0
					preOrderItems.forEach(v => {
						if (v.RESULT === 'T' && v.PRESUB !== '' && v.PRESUB === 'M') {
							preOrderSubCount += 1
						}
					})

					this.$log('preOrderSubCount', preOrderSubCount)
					this.$log('prdList.length', prdList.length)

					if (prdList.length >= preOrderSubCount) {
						this.$alert({
							message: `사전예약으로 주문 가능한 건이 ${preOrderSubCount}건 이므로
            더이상 상품을 선택할 수 없습니다.`
						})
						return
					}
				}
			}

			let presubCd = this.orderType === 'S' ? 'X' : ''
			presubCd = this.orderType === 'C' ? 'C' : presubCd
			// 상품정보 및 판매구분 호출
			if (this.orderType === 'S') {
				// 사전예약의 경우 상품 검색에서 조회
				let res = await fetchPrdOpts01({
					matnr: prd.erp_cd,
					kunnr: this.$store.state['verify'].customer.custNo, // 고객번호
					salesCd: this.$store.getters['common/codyNo'], // 코디번호 코디사번
					presub: presubCd // 사전청약(S): X, 계약이면(C): C, 그외 빈값
				})

				const IT_ZSDS1034 = res.resultObject.item.IT_ZSDS1034
				if (IT_ZSDS1034.length > 0 && IT_ZSDS1034[0].PRDHA) {
					params = Object.assign(params, {
						prdOpts01: res
					})
				}
			}

			this.$router.push({
				name: 'prd-detail',
				params: { ...params }
			})
		},

		// 웹 주문정보 조회 및 저장
		async webOrderInfoSave() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			let result = null

			// 웹 주문정보 조회
			await fetchWebOrderInfoById(mobOrderNo).then(async res => {
				let webParams = res.resultObject
				let order = JSON.parse(webParams.orderInfoJson)

				order = Object.assign(order, {
					prdList: this.$store.getters['prd/prdList'],
					products: this.products,
					deliveryInfos: this.$store.getters['schedule/deliveryInfos']
				})

				webParams.step = '03' // 할인선택
				webParams.statusCode = '104' // 할인선택
				webParams.orderInfoJson = JSON.stringify(order)

				// 웹 주문 저장
				result = await postWebOrderInfoById(mobOrderNo, webParams)
			})
			return result
		},

		// 상품 수량만큼 주문생성
		async payProducts() {
			const products = this.$store.getters['prd/prdList']
			const payProducts = []
			const deliveryInfos = []
			await products.forEach(item => {
				let i = 1
				while (i <= item.options.quantity) {
					payProducts.push({
						prd: item.prd,
						options: item.options,
						payOptions: {
							quantity: 1,
							orderType: undefined,
							returnPromotionApplied: !!item.options.returnPromotionApplied,
							returnPenaltyApplied: !!item.options.returnPenaltyApplied,
							returnPenaltyAmt: item.options.returnPenaltyAmt ?? 0,
							priceAddTypes: [],
							priceDcTypes: []
						},
						esProd: { ...item.esProd },
						valueOptions: {
							min: 0,
							minEvent: 'CustomMinEvent',
							max: item.esProd.PRICE,
							maxEvent: 'CustomMaxEvent'
						},
						oid: item.oid || generateRandomHash(),
						oid2: generateRandomHash(),
						saleInfo: SALE_INFO
					})
					++i

					// 배정판 정보 셋팅
					// workerType 작업담당조직 (SERVICE_MANAGER, DELEGATE_3PL, AIR_3PL, EXPRESS)
					let workerType = ''
					this.workerTypeList.data.filter(value => {
						if (value.prodHCode === item.esProd.PRODH.substr(0, 6)) {
							workerType = value.workerType
						}
					})
					if (
						item.prd.regexpressyn === 'Y' ||
						item.prd.rentalexpressyn === 'Y'
					) {
						workerType = 'EXPRESS'
					}

					let prdOptionItemCdList = []
					prdOptionItemCdList.push(item.options.freeStand?.code)
					prdOptionItemCdList.push(item.options.hangerType?.code)
					prdOptionItemCdList.push(item.options.hangerType2?.code)
					prdOptionItemCdList.push(item.options.filterType?.code)
					prdOptionItemCdList.push(item.options.filterType2?.code)
					prdOptionItemCdList.push(item.options.filterType3?.code)
					prdOptionItemCdList.push(item.options.filterType4?.code)
					prdOptionItemCdList.push(item.options.prdOption2?.code)
					if (item.options.ET_FREE_ADD) {
						item.options.ET_FREE_ADD.filter(v => {
							prdOptionItemCdList.push(v.MATNR)
						})
					}
					prdOptionItemCdList = prdOptionItemCdList.filter(v => {
						return v !== undefined && v !== null && v !== ''
					})

					let deliveryInfo = {
						workerType: workerType,
						goodsCd: item.esProd.GOODS_CD,
						prodNm: item.esProd.GOODS_NM,
						prdOptionItemCdListStr: prdOptionItemCdList.join('@'),
						preOwnerYm: item.options.preOwnerYm,
						prodh: item.esProd.PRODH.substr(0, 6)
					}
					deliveryInfos.push(deliveryInfo)
				}
			})
			this.products = payProducts
			this.$store.dispatch('pay/savePayPrd', payProducts)

			this.$log('deliveryInfos: ', deliveryInfos)
			this.$store.dispatch('schedule/setDeliveryInfos', deliveryInfos)
		},
		// 할인 선택으로 이동
		async next() {
			// 선택한 상품 목록
			const orderPrdList = this.$store.getters['prd/prdList']

			const prdParam = { prodHList: [] }
			for await (const prd of orderPrdList) {
				prdParam.prodHList.push(prd.esProd.PRODH.substr(0, 6))
			}
			const workerTypeList = await workerType(prdParam)
			this.workerTypeList = workerTypeList
			const goNext = await this.chkGoNext(workerTypeList)
			if (!goNext) {
				await this.$alert({
					message: '설치유형 구분이 확인되지 않은 상품이 있습니다.'
				})
				return
			}

			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return
			let arrParam = {
				id: mobOrderNo, // 웹주문번호
				statusCode: '104'
			}
			const res = await mOrderInfoByEtc(arrParam)
			let msg = undefined
			msg = getMessageByStatusCode(res.resultObject.statusCode)
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							dept_cd: this.$store.getters['common/deptCd'],
							type: this.$store.getters['common/type']
						}
					})
					.catch()
			} else {
				this.$store.dispatch('prd/setPrdChk', false)
				await this.preContractCheck()
			}
		},

		// 담당 조직 조회 타입 체크 (담당 조직 조회 API 오류발생 또는 workerType이 UNKNOWN인 경우 alert노출 및 진행불가)
		async chkGoNext(res) {
			let yn = true
			if (!(res && res.code === 'SUCCESS')) {
				yn = false
				this.$log('/booking/worker/type api error')
			} else if (res) {
				res.data.filter(item => {
					if (item.prodHCode === 'UNKNOWN') {
						yn = false
						this.$log(`prodHcode(${item.prodHCode}) is UNKNOWN`)
					}
				})
			}
			return yn
		},

		// 침대패키지 체크
		async preContractCheck() {
			await this.payProducts()
			// 사전계약 관리 판매인 체크
			const preContractSaleCheck = await this.preContractSaleChk()
			if (!preContractSaleCheck) return

			// 웹주문 저장
			const result = await this.webOrderInfoSave()
			// 할인 선택으로 이동
			if (result) {
				this.$router.push({ name: 'order-pay' })
			}
		},

		initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack',
					isNotUseDsHeaderPhysicalBack: true
				},
				this
			)
		},
		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '홈화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) closePopView()
		},

		// 상품 초기화
		prdChkReset() {
			this.bottomPannelClick(false)
			this.$store.dispatch('prd/setPrdChk', false)
		},
		// 사전 계약 관리판매인 변경 체크
		async preContractSaleChk() {
			if (this.orderType !== 'C') return true // 사전계약이 아니면 Pass

			const preOrderList = []
			const products = this.$store.getters['pay/payProducts']
			products.forEach(product => {
				if (product.options.preOrder !== undefined)
					preOrderList.push(product.options.preOrder.ORDER_NO)
			})

			if (preOrderList.length > 0) {
				return await getPreContractSalesChk({
					saleCd: this.$store.getters['common/codyNo'],
					orderNos: [...preOrderList],
					mobOrderNo: this.$store.getters['verify/mobOrderNo']
				})
					.then(async res => {
						const { result } = res.resultObject
						if (result !== 'T') {
							// 주문 삭제
							await this.$alert({
								message:
									'선택된 기존계정의 관리판매인이 변경된 것으로 확인되어 해당 주문이 삭제되었습니다. '
							})
							const mobOrderNo = this.$store.getters['verify/mobOrderNo']

							const res = await fetchWebOrderInfoById(mobOrderNo)
							const webParams = res.resultObject
							webParams.statusCode = '203'

							postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
								this.$log('postRes: ', JSON.stringify(postRes))
								// 모바일 주문현황으로 이동
								this.$router.push({ name: 'order-status' })
							})
							return false
						} else {
							return true
						}
					})
					.catch(err => {
						this.$log(err)
						this.$alert(
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
						)
					})
			}

			return true
		}
	}
}
</script>
